import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { useTranslation } from 'gatsby-plugin-react-i18next';

const query = graphql`
  query AboutMeQuery {
    allFile(
      filter: {
        relativePath: { eq: "wieger-stienstra-SDWbEi94QG0-unsplash.jpg" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(
              height: 650
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
    }
  }
`;

const AboutMe = () => {
  const data = useStaticQuery(query);
  const { t } = useTranslation("common");
  const Image = data.allFile
    ? data.allFile.edges[0].node.childImageSharp.gatsbyImageData
    : "";

  return (
    <section className="mb-24">
      <div className="flex flex-col md:flex-row">
        <GatsbyImage
          image={Image}
          className="h-72 md:h-96 md:w-1/2 rounded-lg"
        />
        <div className="md:w-1/2 md:py-4 md:px-8 flex flex-col text-left justify-start">
          <h2 className="text-3xl mb-8 font-bold dark:text-gray-200">
            {t('about_me_title')}
          </h2>
          <p className="text-base text-gray-600 dark:text-gray-300 mb-4">
            {t('about_me_paragraph_1')}
          </p>
          <p className="text-base text-gray-600 dark:text-gray-300 mb-4">
            {t('about_me_paragraph_2')}
          </p>
          <p className="text-base text-gray-600 dark:text-gray-300 mb-4">
            {t('about_me_paragraph_3')}
          </p>
          <p className="text-base text-gray-600 dark:text-gray-300 mb-4 font-semibold">
            {t('about_me_paragraph_4')}
          </p>
        </div>
      </div>
    </section>
  );
};

export default AboutMe;
