import React from "react"
import { graphql } from "gatsby"

import Seo from "../components/seo"
import PostGrid from "../components/post-grid"
import AboutMe from "../components/about-me"
import { useTranslation } from 'gatsby-plugin-react-i18next';

const IndexPage = ({data, pageContext}) => {
  const { t } = useTranslation(["blog"]);

  return (
    <>
      <Seo language={pageContext.lang} alternate={pageContext.lang === "en" ? "/it" : "/"}/>
      <AboutMe />
      <PostGrid 
        title={"Featured"} 
        posts={data.featured_posts.edges} 
      /> 
      <PostGrid 
        title={t("latest_posts")} 
        cta_label={t("all_posts")} 
        cta_link={"/blog"}
        posts={data.recent_posts.edges} 
      />
    </>
  )
}

export default IndexPage


export const pageQuery = graphql`
  query HomeQuery($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    featured_posts: allMdx(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { template: { eq: "blog-post" }, featured: {eq: true}, draft: {eq: false}, languageCode: {eq: $language} } }
      limit: 6
    ) {
      edges {
        node {
          excerpt(pruneLength: 150)
          fields {
            slug
          }
          frontmatter {
            title
            featuredImage {
              childImageSharp {
                gatsbyImageData(
                  width: 450
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                  )
              }
            }
          }
        }
      }
    }
    recent_posts: allMdx(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { template: { eq: "blog-post" }, featured: {eq: false}, draft: {eq: false}, languageCode: {eq: $language} } }
      limit: 6
    ) {
      edges {
        node {
          excerpt(pruneLength: 150)
          fields {
            slug
          }
          frontmatter {
            title
            tags
            featuredImage {
              childImageSharp {
                gatsbyImageData(
                  width: 450
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                  )
              }
            }
          }
        }
      }
    }
  }
`